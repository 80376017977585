import { useNavigate } from 'react-router-dom';
import useAuth from './UseAuth';
import { useEffect } from 'react';

const GuestGuard = ({ children }: any) => {
  const { isAuthenticated, user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    console.log('GuestGuard', isAuthenticated, user);
    if (isAuthenticated) {
      if (user.stpEmailVerified === true)
        navigate('/', { replace: true });
      else
        navigate('/auth/verifyEmail', { replace: true });
    }
  }, [isAuthenticated, navigate]);

  return children;
};

export default GuestGuard;
