import axios from '../../../utils/axios';
import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch } from 'src/store/Store';

interface ChatInfo {
  sender: string,
  message: string,
  time: number,
}

interface StateType {
  chats: ChatInfo[];
  pending: boolean;
}

const initialState: StateType = {
  chats: [],
  pending: false,
};

export const AiChatSlice = createSlice({
  name: 'aichat',
  initialState,
  reducers: {
    getChats: (state, action) => {
      let received: ChatInfo[] = action.payload;
      let changed = received.length !== state.chats.length;
      if (changed)
        state.chats = received;
      if (state.chats.length === 0) {
        state.chats.push({ message: 'Whats on your mind?', sender: 'ai', time: Date.now() });
      }
      state.pending = (state.chats[state.chats.length - 1].sender === 'user');
    },
    sendMsg: (state: StateType, action) => {
      const msg = action.payload;

      const newMessage: ChatInfo = {
        message: msg,
        sender: 'user',
        time: Date.now(),
      };

      state.chats = [...state.chats, newMessage];
      console.log(state.chats);
      state.pending = true;

      axios.post('/api/ai/chat/send', {
        msg: msg,
      });
    },
  },
});

export const { getChats, sendMsg } = AiChatSlice.actions;

export const fetchChats = () => async (dispatch: AppDispatch) => {
  try {
    const response = await axios.get('/api/ai/chat/list');
    dispatch(getChats(response.data));
  } catch (err: any) {
    throw new Error(err);
  }
};

export default AiChatSlice.reducer;
