import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/full/shared/loadable/Loadable';

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('../layouts/full/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/blank/BlankLayout')));
import AuthGuard from 'src/guards/authGuard/AuthGuard';
import GuestGuard from 'src/guards/authGuard/GuestGaurd';
import Modern from '../views/dashboard/Modern';
import { RouteObject } from 'react-router/lib/router';

/* ****Pages***** */
const Overview = Loadable(lazy(() => import('../views/dashboard/Overview')));
const SamplePage = Loadable(lazy(() => import('../views/sample-page/SamplePage')));
const Error = Loadable(lazy(() => import('../views/authentication/Error')));
const MRecords = Loadable(lazy(() => import('../views/pages/mrecords/mrecords')));
const PersonalInfo = Loadable(lazy(() => import('../views/pages/account-setting/personalInfo')));
const Mrecord = Loadable(lazy(() => import( '../views/pages/mrecords/mrecord')));
const Pricing = Loadable(lazy(() => import( '../views/pages/pricing/Pricing')));
const CS = Loadable(lazy(() => import( '../views/pages/comingsoon')));
const UserProfile = Loadable(lazy(() => import('../views/apps/user-profile/UserProfile')));
const ChatAI = Loadable(lazy(() => import('../views/apps/chat/ChatAI')));
const UserList = Loadable(lazy(() => import('../views/pages/admin/UserList')));

// authentication
const Login = Loadable(lazy(() => import('../views/authentication/auth1/Login')));
const Login2 = Loadable(lazy(() => import('../views/authentication/auth2/Login2')));
const Register = Loadable(lazy(() => import('../views/authentication/auth1/Register')));
const Register2 = Loadable(lazy(() => import('../views/authentication/auth2/Register2')));
const VerifyEmail = Loadable(lazy(() => import('../views/authentication/auth1/verifyEmail')));
const ForgotPassword = Loadable(lazy(() => import('../views/authentication/auth1/ForgotPassword')));
const ForgotPassword2 = Loadable(lazy(() => import('../views/authentication/auth2/ForgotPassword2')));
const ResetPassword = Loadable(lazy(() => import('../views/authentication/auth1/ResetPassword')));

const Router = [
  {
    path: '/',
    element: (
      <AuthGuard>
        <FullLayout />
      </AuthGuard>
    ),
    children: [
      { path: '/', element: <Navigate to='/overview' /> },
      { path: '/overview', element: <Overview /> },
      //{ path: '/test', exact: true, element: <Modern /> },

      { path: '/mrecords', element: <MRecords /> },
      { path: '/mrecord', element: <Mrecord /> },
      { path: '/mrecord/:mrId', element: <Mrecord /> },

      { path: '/twine/blood', element: <CS ptitle={'Digital Twine - Blood'} /> },
      { path: '/twine/vascular', element: <CS ptitle={'Digital Twine - Vascular System'} /> },
      { path: '/twine/brain', element: <CS ptitle={'Digital Twine - Brain'} /> },
      { path: '/twine/kidney', element: <CS ptitle={'Digital Twine - Kidney'} /> },
      { path: '/twine/liver', element: <CS ptitle={'Digital Twine - Liver'} /> },
      { path: '/twine/digestion', element: <CS ptitle={'Digital Twine - Digestion System'} /> },
      { path: '/twine/eye', element: <CS ptitle={'Digital Twine - Eyes'} /> },

      { path: '/live/hbr', element: <CS ptitle={'Live Monitoring - Heart Rate'} /> },
      { path: '/live/box', element: <CS ptitle={'Live Monitoring - Blood Oxygen Level'} /> },
      { path: '/live/glc', element: <CS ptitle={'Live Monitoring - Glucose'} /> },
      { path: '/live/bp', element: <CS ptitle={'Live Monitoring - Blood Pressure'} /> },
      { path: '/live/stress', element: <CS ptitle={'Live Monitoring - Stress'} /> },
      { path: '/live/sleep', element: <CS ptitle={'Live Monitoring - Sleep'} /> },

      { path: '/gadgets', element: <CS ptitle={'Gadgets'} /> },
      { path: '/ai', element: <ChatAI /> },

      { path: '/invoices', element: <Pricing /> },
      { path: '/user', element: <CS ptitle={'user'} /> }, // <UserProfile/>},
      { path: '/personalInfo', element: <PersonalInfo /> },
      { path: '/preferences', element: <CS ptitle={'Preferences'} /> },

      { path: '/admin/users', element: <UserList /> },

      { path: '*', element: <Navigate to='/auth/404' /> },
    ],
  },
  {
    path: '/auth',
    element: (
      <GuestGuard>
        <BlankLayout />
      </GuestGuard>
    ),
    children: [
      { path: '/auth/login', element: <Login /> },
      { path: '/auth/login2', element: <Login2 /> },
      { path: '/auth/register', element: <Register /> },
      { path: '/auth/register2', element: <Register2 /> },
      { path: '/auth/verifyEmail', element: <VerifyEmail /> },
      { path: '/auth/forgot-password', element: <ForgotPassword /> },
      { path: '/auth/forgot-password2', element: <ForgotPassword2 /> },
      { path: '/auth/reset-password', element: <ResetPassword /> },
    ],
  },
  {
    path: '/auth',
    element: <BlankLayout />,
    children: [
      { path: '404', element: <Error /> },
      { path: '*', element: <Navigate to='/auth/404' /> },
    ],
  },
];

export default Router;
