import axios from 'src/utils/axios';
import { createSlice } from '@reduxjs/toolkit';
import { map } from 'lodash';
import { AppDispatch } from 'src/store/Store';

export interface StateType {
  info: {
    firstName: string,
    lastName: string,
    email: string,
    stpEmailVerified?: boolean,
    personalInfo: {
      filled?: boolean,
      age?: number,
      gender?: string,
      height?: number,
      weight?: number,
    },
    ban: {
      banned: boolean,
      banReason?: string,
      adminNote?: string,
    },
    ai?: {
      overall?: {
        summery?: string
        organized?: any
      },
    },
    admin?: {
      access?: { all: boolean }
    },
  };
  ai: {
    personalInfo: boolean
    requested: boolean
    requestValid: boolean
    chat: {
      count: number
    }
  };
}

const initialState = {
  info: {
    firstName: '-',
    lastName: '-',
    email: '-',
    stpEmailVerified: false,
    personalInfo: {
      filled: false,
    },
    ban: {
      banned: false,
    },
    ai: {
      overall: {},
    },
    admin: undefined,
  },
  ai: {
    personalInfo: false,
    requested: false,
    requestValid: false,
    chat: {
      count: 0,
    },
  },
};

export const UserInfoSlice = createSlice({
  name: 'UserInfo',
  initialState,
  reducers: {
    getInfo: (state, action) => {
      state.info = action.payload;
    },
    getAiState: (state, action) => {
      state.ai = { ...state.ai, ...action.payload };
    },
  },
});

export const { getInfo, getAiState } = UserInfoSlice.actions;

export const fetchUserInfo = () => async (dispatch: AppDispatch) => {
  try {
    const response = await axios.get(`/api/account/my-account`);
    dispatch(getInfo(response.data.user));
  } catch (err: any) {
    throw new Error(err);
  }
};

export const fetchUserAiState = () => async (dispatch: AppDispatch) => {
  try {
    const response = await axios.get(`/api/ai/userState`);
    console.log('ais', response.data);
    dispatch(getAiState(response.data));
  } catch (err: any) {
    throw new Error(err);
  }
};

export const goAIProcessing = () => async (dispatch: AppDispatch) => {
  dispatch(getAiState({
    personalInfo: true,
    requested: true,
    requestValid: false,
  }));
};

export default UserInfoSlice.reducer;
