import { useNavigate } from 'react-router-dom';
import useAuth from './UseAuth';
import { useEffect } from 'react';
import { SimpleUserInfo } from '../../types/auth/user';

const AuthGuard = ({ children }: any) => {
  const { isAuthenticated, user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    console.log('AuthGuard',isAuthenticated,user)
    if (!isAuthenticated) {
      navigate('/auth/login', { replace: true });
    } else if (user.stpEmailVerified !== true)
      navigate('/auth/verifyEmail', { replace: true });
  }, [isAuthenticated, navigate]);

  return children;
};

export default AuthGuard;
